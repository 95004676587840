<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="edit-box">
    <h3 class="title">
      付款明细表信息
    </h3>
    <template>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">最终付款方名称：</span><span class="flex1">{{ detailList.openCmpName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">开立协议编号：</span><span class="flex1">{{ detailList.paymentLetterNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">承诺付款日期：</span><span class="flex1">{{ detailList.echainValidate|date }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">承诺付款金额(元)：</span><span class="flex1">{{ detailList.echainApplyAmt|formatMoney }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">付款账户户名：</span><span class="flex1">{{ detailList.openCmpName }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">付款账户账号：</span><span class="flex1">{{ detailList.acNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">付款账户开户行：</span><span class="flex1">{{ detailList.acBankname }}</span>
        </li>
      </ul>
      <el-table
        show-summary
        :summary-method="getInvoiceSummaries"
        class="blue-theme"
        empty-text="暂无数据"
        :data="detailList['finalPayDetailModels']"
      >
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column label="收款方名称" prop="receiveCmpName" />
        <el-table-column label="收款金额(元)" prop="receiveAmt">
          <template slot-scope="scope">
            {{ scope.row.receiveAmt|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="付款金额(元)" prop="payAmt">
          <template slot-scope="scope">
            {{ scope.row.payAmt|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="收款余额(元)" prop="receSum">
          <template slot-scope="scope">
            {{ scope.row.receSum|formatMoney }}
          </template>
        </el-table-column>
      </el-table>
      <el-button type="text" class="down-txt" @click="openFile()">
        点击下载《最终付款明细表》
      </el-button>
    </template>
  </div>
</template>

<script>
import { downloadFile2 } from '@/utils/util'
import { inalPayDetail } from '@/api/tradeFinancing.js'
import moment from 'moment'
export default {
  data() {
    return {
      detailList: {},
      fullscreenLoading: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.fullscreenLoading = true
      inalPayDetail(this.$route.query.id, res => {
        this.fullscreenLoading = false
        if (res.code === 200) {
          this.detailList = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }, () => {
        this.fullscreenLoading = false
      })
    },
    // 合计
    getInvoiceSummaries(param) {
      const { columns, data } = param
      const sums = []
      const filter = this.$options.filters
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (!data) { return }
        const values = data.map(item => Number(item[column.property]))
        if ((column.label === '收款余额(元)' && !values.every(value => isNaN(value)))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = filter.formatMoney(sums[index])
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 打开文件
    openFile() {
      const now = moment(new Date().toString()).format('YYYYMMDD')
      const fileName = 'ZFK' + this.detailList.cmpShortName + now
      downloadFile2(`/v2/transferQuery/downFinalPayDetail/${this.$route.query.id}`, fileName, 'xlsx')
    }
  }
}
</script>
<style lang="scss" scoped>
  .edit-box {
    background-color: #fff;
    padding: 14px 18px;

    .title {
      font-weight: 400;
      padding: 0 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 50px;
      height: 50px;
      border-bottom: 1px solid #EEF1F4;

      .tips-txt {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        width: max-content;
      }
    }

    .basic-information {
      padding-top: 20px;

      li {
        float: left;
        width: 40%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 30px;

        .li-label {
          text-align: right;
          min-width: 126px;
          width: 130px;
          font-weight: 600;
        }

        .flex1 {
          max-width: 88%;
          word-wrap: break-word;
        }

        .red-color {
          color: #ff0000;
          font-size: 20px;
        }

        .br-input {
          width: 65%;
        }
      }
    }

    .down-txt {
      margin: 10px 0;
    }
  }
</style>
